import React, { useState, useEffect, useRef } from "react";
import "firebase/compat/auth";
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Row, Col, Button, Alert, Container, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Slider, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Card, CardBody, TabContent, TabPane, NavItem, NavLink, Input, Form } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light-new.jpg";
import "../../assets/css/Error.css";
import "../../assets/css/ModalStyle.css";
import { registerUserStart, registerUserSuccessful, registerUserFailed } from "../../store/actions";
import validateRequiredFields from "./utilities/registrationFormValidation";
import Select from "react-select";
import businessTypes from "./utilities/businessTypes";
import { specifiedBusinessTypes } from "./utilities/businessTypes";

import BACKEND_DOMAIN from "../../service";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import { canvasPreview } from "../Profile/Utilities/canvasPreview";
import { useDebounceEffect } from "../Profile/Utilities/useDebounceEffect";
// Ensure you have this custom hook
import imageCompression from "browser-image-compression";
//import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

const SEND_OTP_URL = `${BACKEND_DOMAIN}/users/send-code`;
const VERIFY_OTP_URL = `${BACKEND_DOMAIN}/users/verify-otp`;
// React PhoneInput custom styles
const customInputStyle = {
    width: "100%",
    height: "40px",
    backgroundColor: "inherit",
    border: "1px solid #cacaca",
    marginTop: "50px",
};

const customDropdownStyle = {
    backgroundColor: "inherit",
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid transparent",
    color: "inherit",
    height: "fit-content",
    marginTop: 0,
    marginLeft: "-2px",
};

// React Select input field Custom styles
const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        borderColor: "transparent",
        color: "inherit",
        margin: "-7.5px -12px",
        ":hover": {
            borderColor: "transparent",
        },
    }),
    placeholder: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    input: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        margin: "8px -14px",
        width: "101%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? null : isSelected ? "inherit" : isFocused ? "#5664d2" : null,
            color: isDisabled ? null : isSelected ? "#5664d2" : "inherit",
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled ? (isSelected ? "#5664d2" : "#5664d2") : null,
            },
        };
    },
};

const Register = (props) => {
    const [otp, setOtp] = useState("");
    const [otpSentMessage, setOtpSentMessage] = useState("");
    const [otpVerifiedMessage, setOtpVerifiedMessage] = useState("");
    const [otpVerificationSuccessful, setOtpVerificationSuccessful] = useState(false);
    const [registrationError, setRegistrationError] = useState(null);
    const [userRegistrationSuccessful, setUserRegistrationSuccessful] = useState(false);
    const [registrationFailedErrorMessage, setRegistrationFailedErrorMessage] = useState(null);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [bankCode, setBankCode] = useState([]);
    // Loading spinners for said actions:
    const [isSendingOTP, setIsSendingOTP] = useState(false);
    const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
    const [isSubmittingRegistration, setIsSubmittingRegistration] = useState(false);
    const [errors, setErrors] = useState({});
    const [cropping, setCropping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [allFormData, setAllFormData] = useState({
        pane1: {
            name: "",
            contact_person: "",
            phone_number: "",
            alt_phone_number: "",
            location: "",
            email: "",
        },
        pane2: {
            otp: "",
        },
        pane3: {
            sharing_type: "",
            sharing_value: "",
            bank: "",
        },
        pane4: {
            password: "",
            confirmPassword: "",
        },
        pane5: {
            type: "",
            specific_type: "",
            profile_image: "",
            cover_image: "",
        },
    });

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [activeTabProgress, setActiveTabProgress] = useState(1);
    // eslint-disable-next-line
    const [progressValue, setProgressValue] = useState(25);
    // const formRef = useRef(null);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Temp value holder for the business type:
    const [selectedBusinessType, setSelectedBusinessType] = useState(null);
    const [selectedSpecBusinessType, setSelectedSpecBusinessType] = useState(null);
    const [specBusinessTypeOptions, setSpecBusinessTypeOptions] = useState([]);

    // OTP sending and verifying error Message:
    const [otpNotSentError, setOtpNotSentError] = useState(null);
    const [otpVerificationError, setOtpVerificationError] = useState(null);
    const [resetTimer, setResetTimer] = useState(0);

    // image processing state
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageType, setSelectedImageType] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [scale, setScale] = useState(1);

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [isNext, setIsNext] = useState(true);

    const aspect = selectedImageType === "profile_image" ? 1 : 16 / 9;
    // const toggle = () => setModal(!modal);

    //  fetch banks
    const fetchBanks = async () => {
        const apiURL = `${BACKEND_DOMAIN}/wallets/providers/banks`;

        try {
            const response = await axios.get(apiURL);
            console.log("Full response:", response.data); // Check full structure

            if (response.data && Array.isArray(response.data.data)) {
                setBankCode(response.data.data); // Correcting to extract the array
            } else {
                console.error("Unexpected data format:", response.data);
                setBankCode([]); // Default to empty array if structure is incorrect
            }
        } catch (e) {
            console.error("Error fetching Banks -> ", e);
        }
    };

    useEffect(() => {
        fetchBanks();
    }, []);

    const handleBankChange = (event) => {
        const { value } = event.target;
        setAllFormData((prevData) => ({
            ...prevData,
            pane3: {
                ...prevData.pane3,
                bank: value,
            },
        }));
    };
    // Sending OTP

    // otp  change

    const handleOtpChange = (e) => {
        const newOtp = e.target.value;
        setOtp(newOtp);

        // Update allFormData.pane2 with the new OTP
        setAllFormData((prevState) => ({
            ...prevState,
            pane2: {
                ...prevState.pane2,
                otp: newOtp,
            },
        }));
    };

    const sendOTP = async () => {
        const { phone_number, email } = allFormData.pane1;

        if (phone_number === "" || email === "") {
            setOtpNotSentError("Email and Phone number added are INVALID.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);

            return;
        }

        setIsSendingOTP(true);

        try {
            const otpFormData = new FormData();
            otpFormData.append("otp", otp);

            otpFormData.append("phone_number", phone_number);
            otpFormData.append("email", email);
            const res = await axios.post(SEND_OTP_URL, otpFormData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setOtpSentMessage(res.data["message"]);

            setTimeout(() => {
                setOtpSentMessage(null);
            }, 3000);
        } catch (e) {
            setOtpNotSentError("An Error occurred.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);
        } finally {
            setIsSendingOTP(false);
        }
    };

    // Resend otp:
    const startTimer = () => {
        setResetTimer(30);

        const interval = setInterval(() => {
            setResetTimer((prev) => {
                if (prev === 1) {
                    clearInterval(interval); // Clear interval when timer reaches 0
                }
                return prev - 1;
            });
        }, 1000);
    };

    const resendOTPWithTimer = async () => {
        await sendOTP();

        startTimer();
    };

    // Verifying the sent OTP
    const handleOTPVerification = async (e) => {
        const { phone_number, email } = allFormData.pane1;
        const { otp } = allFormData.pane2;

        if (!otp) {
            setOtpNotSentError("A valid OTP is Required.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);

            return;
        }

        setIsVerifyingOTP(true);

        try {
            const otpVerificationFormData = new FormData();

            otpVerificationFormData.append("phone_number", phone_number);
            otpVerificationFormData.append("email", email);
            otpVerificationFormData.append("otp", otp);

            const res = await axios.post(VERIFY_OTP_URL, otpVerificationFormData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setOtpVerifiedMessage(res.data["message"]);

            setTimeout(() => {
                toggleTab(activeTab + 1);
                toggleTabProgress(activeTab + 1);
                setOtpVerifiedMessage(null);
            }, 2000);

            setOtpVerificationSuccessful(true);
            setIsOtpVerified(true);
        } catch (e) {
            setOtpVerificationError("OTP submitted is INVALID.");
            setTimeout(() => {
                setOtpVerificationError(null);
            }, 3000);
            setIsOtpVerified(false);
        } finally {
            setIsVerifyingOTP(false);
            setIsOtpVerified(false);
        }
    };

    const onBusinessTypeChange = (selectedBusinessType) => {
        setSelectedBusinessType(selectedBusinessType);

        //Find index of selected category:
        const businessTypeIndex = specifiedBusinessTypes.findIndex(
            (bType) => Object.keys(bType)[0] === `${selectedBusinessType.label}`
        );
        setSpecBusinessTypeOptions(specifiedBusinessTypes[businessTypeIndex][selectedBusinessType.label]);

        setSelectedSpecBusinessType(null);
        setAllFormData((prevState) => ({
            ...prevState,
            pane5: {
                ...prevState.pane5,
                specific_type: "",
            },
        }));

        setAllFormData((prevState) => ({
            ...prevState,
            pane5: {
                ...prevState.pane5,
                type: selectedBusinessType.value,
            },
        }));
    };

    const onSpecBusinessTypeChange = (selectedSpecBusinessType) => {
        setSelectedSpecBusinessType(selectedSpecBusinessType);

        setAllFormData((prevState) => ({
            ...prevState,
            pane5: {
                ...prevState.pane5,
                specific_type: selectedSpecBusinessType.value,
            },
        }));
    };

    // input checkter per tab

    // const validateForm = (tab) => {
    //     let isValid = true;
    //     let errors = {};

    //     if (tab === 1) {
    //         if (!allFormData.pane1.name) {
    //             errors.name = "Business Name is required";

    //             isValid = false;
    //         }
    //         if (!allFormData.pane1.contact_person) {
    //             errors.contact_person = "Contact Person is required";
    //             isValid = false;
    //         }
    //         if (!allFormData.pane1.phone_number) {
    //             errors.phone_number = "WhatsApp Business Number is required";
    //             isValid = false;
    //         }
    //         if (!allFormData.pane1.alt_phone_number) {
    //             errors.alt_phone_number = "Contact Person Phone Number is required";
    //             isValid = false;
    //         }
    //         if (!allFormData.pane1.email) {
    //             errors.email = "Email is required";
    //             isValid = false;
    //         }
    //         if (!allFormData.pane1.location) {
    //             errors.location = "Location is required";
    //             isValid = false;
    //         }
    //     }
    //     if (tab === 2) {

    //         if (!allFormData.pane2.otp) {
    //             errors.otp = "OTP is required";
    //             isValid = false;
    //         }

    //     }
    //     if (tab === 3) {

    //         if (!allFormData.pane3.password) {
    //             errors.password = "Password is required";
    //             isValid = false;
    //         }
    //         if (!allFormData.pane3.confirmPassword) {
    //             errors.confirmPassword = "Confirm password is required";
    //             isValid = false;
    //         }

    //     }
    //     if(tab === 4){
    //         if (!allFormData.pane4.type) {
    //             errors.type = "Type is required";
    //             isValid = false;
    //         }
    //         if (!allFormData.pane4.specific_type) {
    //             errors.specific_type = "specific is required";
    //             isValid = false;
    //         }

    //         if (!allFormData.pane4.profile_image) {
    //             errors.profile_image = "Profile image is required";
    //             isValid = false;
    //         }
    //         if (!allFormData.pane4.cover_image) {
    //             errors.cover_image = "Cover Image is required";
    //             isValid = false;
    //         }
    //     }

    //     setErrors(errors);
    //     return isValid;
    // };

    const validateForm = (tab) => {
        let isValid = true;
        let errors = {};

        if (isNext) {
            // Only validate if the user is trying to move forward
            if (tab === 1) {
                if (!allFormData.pane1.name) {
                    errors.name = "Business Name is required";
                    isValid = false;
                }
                if (!allFormData.pane1.contact_person) {
                    errors.contact_person = "Contact Person is required";
                    isValid = false;
                }
                if (!allFormData.pane1.phone_number) {
                    errors.phone_number = "WhatsApp Business Number is required";
                    isValid = false;
                }
                if (!allFormData.pane1.alt_phone_number) {
                    errors.alt_phone_number = "Contact Person Phone Number is required";
                    isValid = false;
                }
                if (!allFormData.pane1.email) {
                    errors.email = "Email is required";
                    isValid = false;
                }
                if (!allFormData.pane1.location) {
                    errors.location = "Location is required";
                    isValid = false;
                }
            }
            if (tab === 2) {
                if (!allFormData.pane2.otp) {
                    errors.otp = "OTP is required";
                    isValid = false;
                }
            }
            if (tab === 3) {
                if (!allFormData.pane3.sharing_type) {
                    errors.sharing_type = "Sharing selection Required";
                    isValid = false;
                }
                if (!allFormData.pane3.sharing_value) {
                    errors.sharing_value = "Select the value";
                    isValid = false;
                }
                if (!allFormData.pane3.bank) {
                    errors.bank = "Choose a bank";
                    isValid = false;
                }
            }
            if (tab === 4) {
                if (!allFormData.pane4.password) {
                    errors.password = "Password is required";
                    isValid = false;
                }
                if (!allFormData.pane4.confirmPassword) {
                    errors.confirmPassword = "Confirm password is required";
                    isValid = false;
                }
            }
            if (tab === 5) {
                if (!allFormData.pane5.type) {
                    errors.type = "Type is required";
                    isValid = false;
                }
                if (!allFormData.pane5.specific_type) {
                    errors.specific_type = "Specific type is required";
                    isValid = false;
                }
                if (!allFormData.pane5.profile_image) {
                    errors.profile_image = "Profile image is required";
                    isValid = false;
                }
                if (!allFormData.pane5.cover_image) {
                    errors.cover_image = "Cover Image is required";
                    isValid = false;
                }
            }
        }

        setErrors(errors);
        return isValid;
    };

    const toggleTab = (tab) => {
        if (validateForm(activeTab)) {
            if (activeTab !== tab) {
                if (tab >= 1 && tab <= 5) {
                    setActiveTab(tab);
                    toggleTabProgress(tab);
                }
            }
        } else {
            console.log("one or multiple field(s) empty.");
        }
    };

    const toggleTabProgress = (tab) => {
        if (activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 5) {
                setActiveTabProgress(tab);

                if (tab === 1) {
                    setProgressValue(20);
                }
                if (tab === 2) {
                    setProgressValue(40);
                }
                if (tab === 3) {
                    setProgressValue(60);
                }
                if (tab === 4) {
                    setProgressValue(80);
                }
                if (tab === 5) {
                    setProgressValue(100);
                }
            }
        }
    };

    const handleFileChange = (e, pane) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgSrc(reader.result.toString() || "");
                setSelectedImageType(name);
                setModalOpen(true); // Open the crop modal
            });
            reader.readAsDataURL(file);
        }
    };
    const handleSaveCrop = async (pane) => {
        const canvas = previewCanvasRef.current;
        setCropping(true);
        setIsLoading(true);

        // Convert canvas to blob
        canvas.toBlob(async (blob) => {
            if (blob) {
                try {
                    const compressedBlob = await imageCompression(blob, {
                        maxSizeMB: 0.3,
                        maxWidthOrHeight: 900,
                        useWebWorker: true,
                    });

                    // console.log('slug name is ',allFormData.pane1.name);
                    const myfilename = allFormData.pane1.name + "_" + selectedImageType;
                    //  console.log('updated file name is ',myfilename);

                    // Extract MIME type and file extension
                    const mimeType = compressedBlob.type || blob.type;
                    const fileExtension = mimeType.split("/")[1];
                    const croppedFile = new File([compressedBlob], `${myfilename}.${fileExtension}`, {
                        type: mimeType,
                    });

                    // Log the file size and dimensions (optional, but might help for debugging)
                    const currentSizeKB = croppedFile.size / 1024;
                    // console.log(`Image size is ${currentSizeKB.toFixed(2)} KB`);

                    // Directly set the file in form data
                    if (selectedImageType === "profile_image") {
                        setAllFormData((prevState) => ({
                            ...prevState,
                            [pane]: {
                                ...prevState[pane],
                                profile_image: croppedFile, // Store the cropped file directly
                            },
                        }));
                    } else {
                        setAllFormData((prevState) => ({
                            ...prevState,
                            [pane]: {
                                ...prevState[pane],
                                cover_image: croppedFile, // Store the cropped file directly
                            },
                        }));
                    }

                    // Close modal and reset crop
                    setModalOpen(false);
                    setCompletedCrop(null);
                } catch (error) {
                    console.error("Error during image compression:", error);
                } finally {
                    setCropping(false);
                    setIsLoading(false);
                }
            }
        });
    };

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
            }
        },
        100,
        [completedCrop, scale]
    );

    // const handleFileChange = (e, pane) => {

    //     const file = e.target.files[0];

    //     // Create a new FormData object to append files
    //     const fileData = new FormData();
    //     fileData.append(e.target.name, file, file.name);

    // setAllFormData((prevState) => ({
    //     ...prevState,
    //     [pane]: {
    //         ...prevState[pane],
    //         ...Object.fromEntries(fileData.entries()), // Merge with existing data
    //     },
    // }));
    // };

    // const handleFileChange = (e, pane) => {
    //   const file = e.target.files[0];
    //   if (!file) return;

    //   setCurrentInputName(e.target.name); // Store the input name
    //   setCurrentPane(pane)
    //   setModal(true);

    //   setCrop(null); // Makes crop preview update between images.
    //   const reader = new FileReader();
    //   reader.addEventListener('load', () => setSrc(reader.result?.toString() || ''));
    //   reader.readAsDataURL(file);
    // };

    // image crop edit
    // function onImageLoad(e) {

    //     if (aspect) {
    //       const { width, height } = e.currentTarget;
    //       const newCrop = centerAspectCrop(width, height, aspect);

    //       setCrop(newCrop);
    //     }
    //   }

    // async function onDownloadCropClick() {
    //     const image = imgRef.current;
    //     const previewCanvas = previewCanvasRef.current;
    //     if (!image || !previewCanvas || !completedCrop) {
    //       throw new Error('Crop canvas does not exist');
    //     }

    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;

    //     const offscreen = new OffscreenCanvas(
    //       completedCrop.width * scaleX,
    //       completedCrop.height * scaleY,
    //     );
    //     const ctx = offscreen.getContext('2d');
    //     if (!ctx) {
    //       throw new Error('No 2d context');
    //     }

    //     ctx.drawImage(
    //       previewCanvas,
    //       0,
    //       0,
    //       previewCanvas.width,
    //       previewCanvas.height,
    //       0,
    //       0,
    //       offscreen.width,
    //       offscreen.height,
    //     );

    //     const blob = await offscreen.convertToBlob({
    //       type: 'image/png',
    //     });

    //     if (blobUrlRef.current) {
    //       URL.revokeObjectURL(blobUrlRef.current);
    //     }
    //     blobUrlRef.current = URL.createObjectURL(blob);

    //     if (hiddenAnchorRef.current) {
    //       hiddenAnchorRef.current.href = blobUrlRef.current;
    //       hiddenAnchorRef.current.download = `${currentInputName}-cropped.png`;
    //       hiddenAnchorRef.current.click();
    //     }

    //     console.log('image name ',currentInputName,' image is ',`${currentInputName}-cropped.png`);
    //     // Append the cropped image to formData
    //     const fileData = new FormData();
    //     fileData.append(currentInputName, blob, `${currentInputName}-cropped.png`);

    //     setAllFormData((prevState) => ({
    //         ...prevState,
    //         [currentPane]: {
    //           ...prevState[currentPane],
    //           [currentInputName]: fileData.get(currentInputName),
    //         },
    //       }));

    //     // Close modal
    //     setModal(false);
    //   }

    // end edit
    const handlePasswordChange = (e) => {
        setAllFormData((prevState) => ({
            ...prevState,
            pane4: {
                ...prevState.pane4,
                password: e.target.value,
            },
        }));
    };

    // function handleToggleAspectClick() {
    //     if (aspect) {
    //       setAspect(null);
    //     } else {
    //       setAspect(16 / 9);

    //       if (imgRef.current) {
    //         const { width, height } = imgRef.current;
    //         const newCrop = centerAspectCrop(width, height, 16 / 9);
    //         setCrop(newCrop);
    //         setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    //       }
    //     }
    //   }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleConfirmPasswordChange = (e) => {
        setAllFormData((prevState) => ({
            ...prevState,
            pane4: {
                ...prevState.pane4,
                confirmPassword: e.target.value,
            },
        }));
    };
    const handleBlur = () => {
        const { password, confirmPassword } = allFormData.pane4;
        setPasswordMatch(password === confirmPassword);
    };

    const handleValidSubmit = async (event, values) => {
        event.preventDefault();
        console.log("submitting");
        // Ensure all required fields are provided before form submission:

        const anyInvalidField = validateRequiredFields(allFormData, otpVerificationSuccessful);
        console.log("all form data ", allFormData, " verification ", otpVerificationSuccessful);

        console.log(anyInvalidField);

        setRegistrationError(anyInvalidField);
        if (anyInvalidField) {
            setTimeout(() => {
                setRegistrationError(null);
            }, 3000);

            console.log("field empty and otp verification ");
            return;
        } else {
            console.log("data is valid");
        }

        props.dispatch(registerUserStart());
        console.log("dispatching");
        setIsSubmittingRegistration(true);

        try {
            const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/`;

            const formData = new FormData();

            Object.keys(allFormData).forEach((pane) => {
                Object.keys(allFormData[pane]).forEach((field) => {
                    formData.append(field, allFormData[pane][field]);
                });
            });

            console.log("all data ", formData);

            const response = await axios.post(apiUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            props.dispatch(registerUserSuccessful(response.data));

            setUserRegistrationSuccessful(true);

            if (activeTab === 5) {
                setTimeout(() => {
                    navigate("/route-money/merchant/login");
                }, 2000);
            } else {
                console.log("going to next pane");
                setAllFormData((prevState) => {
                    return {
                        ...prevState,
                        [`pane${activeTab}`]: values,
                    };
                });
                toggleTab(activeTab + 1);
            }
        } catch (error) {
            // Extract the first error from the API error messages and display it for 4 seconds:
            const errorToDisplay = Object.values(error.response.data.errors)[0];
            console.log("error  on catch ", errorToDisplay);
            setRegistrationFailedErrorMessage(errorToDisplay);

            setTimeout(() => {
                setRegistrationFailedErrorMessage(null);
            }, 3000);

            props.dispatch(registerUserFailed(error.message));
        } finally {
            setIsSubmittingRegistration(false);
        }
    };

    useEffect(() => {
        props.dispatch(registerUserFailed(""));
        document.body.classList.add("auth-body-bg");
    }, [props]);

    return (
        <React.Fragment>
            <div className="pt-3 pb-3">
                <Container fluid={true} className="vh-100 d-flex justify-content-center align-items-center">
                    <Row className="d-flex justify-content-center">
                        <div className="text-center">
                            <Link to="#" className="logo">
                                <img src={logodark} height="35" alt="logo" className="auth-logo logo-light mx-auto" />
                                <img src={logolight} height="35" alt="logo" className="auth-logo logo-dark mx-auto" />
                            </Link>
                            <h4 className="font-size-18 mt-4">Register account</h4>
                        </div>

                        {userRegistrationSuccessful && <Alert color="success">Registration Done Successfully.</Alert>}
                        {registrationFailedErrorMessage && (
                            <Alert color="warning">{registrationFailedErrorMessage} !</Alert>
                        )}

                        <Col xs="12" sm="8" md="10" lg="12">
                            <Card>
                                <CardBody>
                                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 1,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(1);
                                                        setIsNext(false);
                                                    }}
                                                >
                                                    <span className="step-number">01</span>
                                                    <span className="step-title">Merchant Details</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 2,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(2);
                                                        setIsNext(false);
                                                    }}
                                                >
                                                    <span className="step-number">02</span>
                                                    <span className="step-title">Verification</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 3,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(3);
                                                    }}
                                                >
                                                    <span className="step-number">03</span>
                                                    <span className="step-title">Sharing & Bank</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 4,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(4);
                                                    }}
                                                >
                                                    <span className="step-number">04</span>
                                                    <span className="step-title">Set Password</span>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 4,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(4);
                                                    }}
                                                >
                                                    <span className="step-number">05</span>
                                                    <span className="step-title">Set-Up profile</span>
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <Form>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="name">
                                                                    Business Name <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="name"
                                                                    value={allFormData.pane1.name}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                name: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    //className="form-control"
                                                                    className={`form-control ${
                                                                        errors.name ? "is-invalid" : ""
                                                                    }`}
                                                                    id="name"
                                                                    placeholder="Enter Business Name"
                                                                />
                                                                {errors.name && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.name}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="contact_person">
                                                                    Contact Person{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="contact_person"
                                                                    value={allFormData.pane1.contact_person}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                contact_person: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className={`form-control ${
                                                                        errors.contact_person ? "is-invalid" : ""
                                                                    }`}
                                                                    id="contact_person"
                                                                    placeholder="Enter Contact Person Name"
                                                                />
                                                                {errors.contact_person && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.contact_person}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="phone_number">
                                                                    WhatsApp Business Number{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>

                                                                <PhoneInput
                                                                    name="phone_number"
                                                                    inputStyle={customInputStyle}
                                                                    dropdownStyle={customDropdownStyle}
                                                                    country={"ke"}
                                                                    onlyCountries={["ke", "ug", "tz", "rw"]}
                                                                    value={allFormData.pane1.phone_number}
                                                                    onChange={(value) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                phone_number: "+" + value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    inputClass={`form-control ${
                                                                        errors.phone_number ? "is-invalid" : ""
                                                                    }`}
                                                                    className={`${
                                                                        errors.phone_number ? "is-invalid" : ""
                                                                    }`}
                                                                    dropdownClass="form-control"
                                                                />
                                                                {errors.phone_number && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.phone_number}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="alt_phone_number"
                                                                >
                                                                    Contact Person Phone Number{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>

                                                                <PhoneInput
                                                                    name="alt_phone_number"
                                                                    inputStyle={customInputStyle}
                                                                    dropdownStyle={customDropdownStyle}
                                                                    country={"ke"}
                                                                    onlyCountries={["ke", "ug", "tz", "rw"]}
                                                                    value={allFormData.pane1.alt_phone_number}
                                                                    onChange={(value) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                alt_phone_number: "+" + value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    className={`${
                                                                        errors.alt_phone_number ? "is-invalid" : ""
                                                                    }`}
                                                                    inputClass={`form-control ${
                                                                        errors.alt_phone_number ? "is-invalid" : ""
                                                                    }`}
                                                                    dropdownClass="form-control"
                                                                />
                                                                {errors.alt_phone_number && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.alt_phone_number}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="email">
                                                                    Email <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="email"
                                                                    value={allFormData.pane1.email}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                email: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="email"
                                                                    // className="form-control"
                                                                    className={`form-control ${
                                                                        errors.email ? "is-invalid" : ""
                                                                    }`}
                                                                    id="email"
                                                                    placeholder="Enter email"
                                                                />
                                                                {errors.email && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.email}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="location">
                                                                    Location <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="location"
                                                                    value={allFormData.pane1.location}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                location: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    // className="form-control"
                                                                    className={`form-control ${
                                                                        errors.location ? "is-invalid" : ""
                                                                    }`}
                                                                    id="location"
                                                                    placeholder="Enter Location"
                                                                />
                                                                {errors.location && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.location}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                <div className="d-flex justify-content-between">
                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                            toggleTabProgress(activeTab - 1);
                                                            setIsNext(false);
                                                        }}
                                                    >
                                                        Previous
                                                    </Button>

                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                            toggleTabProgress(activeTab + 1);
                                                            resendOTPWithTimer();
                                                            setIsNext(true);
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <div>
                                                    <Form>
                                                        <Row>
                                                            <Col lg="10">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="otp">
                                                                        OTP Verification{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>
                                                                    {otpSentMessage && (
                                                                        <Alert color="success">{otpSentMessage}</Alert>
                                                                    )}
                                                                    {otpNotSentError && (
                                                                        <Alert color="danger">{otpNotSentError}</Alert>
                                                                    )}
                                                                    {otpVerificationError && (
                                                                        <Alert color="danger">
                                                                            {otpVerificationError}
                                                                        </Alert>
                                                                    )}
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        {/* <Input
                                                                            name="otp"
                                                                          //  value={otp}
                                                                              value={otp}
                                                                            onChange={(e) => setOtp(e.target.value)}
                                                                            type="text"
                                                                            className={`form-control`}
                                                                            id="otp"
                                                                            placeholder="Enter OTP"
                                                                            style={{ flex: 1 }}
                                                                        /> */}

                                                                        <Input
                                                                            name="otp"
                                                                            value={otp}
                                                                            onChange={handleOtpChange}
                                                                            type="text"
                                                                            //  className="form-control"
                                                                            className={`form-control ${
                                                                                errors.otp ? "is-invalid" : ""
                                                                            }`}
                                                                            id="otp"
                                                                            placeholder="Enter OTP"
                                                                            style={{ flex: 1 }}
                                                                        />

                                                                        <Button
                                                                            type="button"
                                                                            color="primary"
                                                                            onClick={resendOTPWithTimer}
                                                                            className="ms-2"
                                                                            disabled={isSendingOTP || resetTimer > 0}
                                                                        >
                                                                            {isSendingOTP ? (
                                                                                <>
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                    <span className="ms-2">
                                                                                        Sending..
                                                                                    </span>
                                                                                </>
                                                                            ) : resetTimer > 0 ? (
                                                                                `Resend in: ${resetTimer}s`
                                                                            ) : (
                                                                                "Send OTP"
                                                                            )}
                                                                        </Button>
                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            onClick={handleOTPVerification}
                                                                            className="ms-2"
                                                                            disabled={isVerifyingOTP}
                                                                        >
                                                                            {isVerifyingOTP ? (
                                                                                <>
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                    <span className="ms-2">
                                                                                        Verifying..
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                "Verify OTP"
                                                                            )}
                                                                        </Button>
                                                                    </div>
                                                                    {otpVerifiedMessage && (
                                                                        <Alert color="success">
                                                                            {otpVerifiedMessage}
                                                                        </Alert>
                                                                    )}
                                                                    {errors.otp && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.otp}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                    <div className="d-flex justify-content-between">
                                                        <Button
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#5664d2",
                                                            }}
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1);
                                                                toggleTabProgress(activeTab - 1);
                                                                setIsNext(false);
                                                            }}
                                                        >
                                                            Previous
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#5664d2",
                                                            }}
                                                            // onClick={() => {
                                                            //     toggleTab(activeTab + 1);
                                                            //     toggleTabProgress(activeTab + 1);
                                                            // }}
                                                            onClick={() => {
                                                                if (isOtpVerified) {
                                                                    toggleTab(activeTab + 1);
                                                                    toggleTabProgress(activeTab + 1);
                                                                    setIsNext(true);
                                                                } else {
                                                                    setOtpNotSentError(
                                                                        "Please verify your OTP before proceeding."
                                                                    );
                                                                    setTimeout(() => {
                                                                        setOtpNotSentError(null);
                                                                    }, 3000);
                                                                }
                                                            }}
                                                        >
                                                            Next
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={3}>
                                                <Form encType="multipart/form-data">
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="sharingType">
                                                                    Sharing Type <span className="text-danger">*</span>
                                                                </Label>
                                                                <div>
                                                                    <div className="form-check form-check-inline">
                                                                        <Input
                                                                            type="radio"
                                                                            name="sharing_type"
                                                                            id="percentage"
                                                                            value="percentage"
                                                                            checked={
                                                                                allFormData.pane3.sharing_type ===
                                                                                "percentage"
                                                                            }
                                                                            onChange={(e) =>
                                                                                setAllFormData((prevState) => ({
                                                                                    ...prevState,
                                                                                    pane3: {
                                                                                        ...prevState.pane3,
                                                                                        sharing_type: e.target.value,
                                                                                    },
                                                                                }))
                                                                            }
                                                                            className="form-check-input"
                                                                        />
                                                                        <Label
                                                                            className="form-check-label"
                                                                            htmlFor="percentage"
                                                                        >
                                                                            Percentage
                                                                        </Label>
                                                                    </div>

                                                                    <div className="form-check form-check-inline">
                                                                        <Input
                                                                            type="radio"
                                                                            name="sharing_type"
                                                                            id="flat"
                                                                            value="flat"
                                                                            checked={
                                                                                allFormData.pane3.sharing_type ===
                                                                                "flat"
                                                                            }
                                                                            onChange={(e) =>
                                                                                setAllFormData((prevState) => ({
                                                                                    ...prevState,
                                                                                    pane3: {
                                                                                        ...prevState.pane3,
                                                                                        sharing_type: e.target.value,
                                                                                    },
                                                                                }))
                                                                            }
                                                                            className="form-check-input"
                                                                        />
                                                                        <Label
                                                                            className="form-check-label"
                                                                            htmlFor="flat"
                                                                        >
                                                                            Flat
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                                {errors.sharing_type && (
                                                                    <div className="invalid-feedback d-block">
                                                                        {errors.sharing_type}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="sharing_value">
                                                                    Sharing Value <span className="text-danger">*</span>
                                                                </Label>
                                                                <small className="text-muted d-block mb-2">
                                                                    (If you select <strong>Flat</strong>, enter the
                                                                    actual amount. If you select{" "}
                                                                    <strong>Percentage</strong>, enter the percentage
                                                                    share.)
                                                                </small>
                                                                <Input
                                                                    name="sharing_value"
                                                                    value={allFormData.pane3.sharing_value}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane3: {
                                                                                ...prevState.pane3,
                                                                                sharing_value: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className={`form-control ${
                                                                        errors.sharing_value ? "is-invalid" : ""
                                                                    }`}
                                                                    id="sharing_value"
                                                                    placeholder="Enter sharing value"
                                                                />
                                                                {errors.sharing_value && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.sharing_value}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>

                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="bankName">
                                                                    Bank
                                                                </Label>
                                                                <Input
                                                                    type="select"
                                                                    name="bankName"
                                                                    id="bankName"
                                                                    value={allFormData.pane3.bank || ""}
                                                                    onChange={handleBankChange}
                                                                >
                                                                    <option value="">Select Bank</option>
                                                                    {Array.isArray(bankCode) &&
                                                                        bankCode.map((bank) => (
                                                                            <option key={bank.id} value={bank.code}>
                                                                                {bank.name}
                                                                            </option>
                                                                        ))}
                                                                </Input>

                                                                {/* {errors.bank && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.bank}
                                                                    </div>
                                                                )} */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                <div className="d-flex justify-content-between">
                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                            toggleTabProgress(activeTab - 1);
                                                            setIsNext(false);
                                                        }}
                                                    >
                                                        Previous
                                                    </Button>

                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                            toggleTabProgress(activeTab + 1);
                                                            setIsNext(true);
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={4}>
                                                <Form encType="multipart/form-data">
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-1">
                                                                <Label className="form-label" htmlFor="password">
                                                                    Password <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="password"
                                                                    value={allFormData.pane4.password}
                                                                    onChange={handlePasswordChange}
                                                                    onBlur={handleBlur}
                                                                    validate={{
                                                                        required: true,
                                                                    }}
                                                                    type={passwordVisible ? "text" : "password"}
                                                                    className={`form-control ${
                                                                        errors.password ? "is-invalid" : ""
                                                                    }`}
                                                                    id="password"
                                                                    placeholder="Enter password"
                                                                />
                                                                {errors.password && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.password}
                                                                    </div>
                                                                )}
                                                                <div className="d-flex justify-content-end mt-1">
                                                                    <small
                                                                        className="text-muted cursor-pointer"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        {passwordVisible ? "Hide" : "Show"}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3 d-flex flex-column">
                                                                <Label className="form-label" htmlFor="confirmPassword">
                                                                    Confirm Password{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="confirmPassword"
                                                                    value={allFormData.pane4.confirmPassword}
                                                                    onChange={handleConfirmPasswordChange}
                                                                    onBlur={handleBlur}
                                                                    type={passwordVisible ? "text" : "password"}
                                                                    className={`form-control ${
                                                                        passwordMatch ? "" : "is-invalid"
                                                                    } ${errors.confirmPassword ? "is-invalid" : ""}`}
                                                                    id="confirmPassword"
                                                                    placeholder="Confirm password"
                                                                />
                                                                {errors.confirmPassword && (
                                                                    <div className="invalid-feedback">
                                                                        {errors.confirmPassword}
                                                                    </div>
                                                                )}
                                                                {!passwordMatch && (
                                                                    <div className="invalid-feedback">
                                                                        Passwords do not match
                                                                    </div>
                                                                )}
                                                                <small
                                                                    style={{
                                                                        marginTop: !passwordMatch ? "-18px" : 3,
                                                                    }}
                                                                    className="text-muted cursor-pointer align-self-end"
                                                                    onClick={togglePasswordVisibility}
                                                                >
                                                                    {passwordVisible ? "Hide" : "Show"}
                                                                </small>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    {registrationError && (
                                                        <Alert color="danger">{registrationError}</Alert>
                                                    )}
                                                    {registrationFailedErrorMessage && (
                                                        <Alert color="danger">{registrationFailedErrorMessage}</Alert>
                                                    )}
                                                </Form>
                                                <div className="d-flex justify-content-between">
                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                            toggleTabProgress(activeTab - 1);
                                                            setIsNext(false);
                                                        }}
                                                    >
                                                        Previous
                                                    </Button>

                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                            toggleTabProgress(activeTab + 1);
                                                            setIsNext(true);
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={5}>
                                                <div>
                                                    <AvForm
                                                        onValidSubmit={handleValidSubmit}
                                                        encType="multipart/form-data"
                                                    >
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        General Business Type{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>

                                                                    <Select
                                                                        placeholder="Select or Type to filter..."
                                                                        options={businessTypes}
                                                                        value={selectedBusinessType}
                                                                        onChange={onBusinessTypeChange}
                                                                        className={`form-control ${
                                                                            errors.type ? "is-invalid" : ""
                                                                        }`}
                                                                        styles={colourStyles}
                                                                    />
                                                                    {errors.type && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.type}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        Specific Business Type{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>

                                                                    <Select
                                                                        placeholder="Select or Type to filter..."
                                                                        options={
                                                                            !selectedBusinessType
                                                                                ? [
                                                                                      {
                                                                                          value: "",
                                                                                          label: "Select General Type First",
                                                                                      },
                                                                                  ]
                                                                                : specBusinessTypeOptions
                                                                        }
                                                                        value={selectedSpecBusinessType}
                                                                        onChange={onSpecBusinessTypeChange}
                                                                        className={`form-control ${
                                                                            errors.specific_type ? "is-invalid" : ""
                                                                        }`}
                                                                        styles={colourStyles}
                                                                    />
                                                                    {errors.specific_type && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.specific_type}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="profile_image"
                                                                    >
                                                                        Profile Photo{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>

                                                                    {/* test input cropped photo */}
                                                                    <Input
                                                                        name="profile_image"
                                                                        onChange={(e) => handleFileChange(e, "pane5")}
                                                                        validate={{
                                                                            required: true,
                                                                        }}
                                                                        type="file"
                                                                        className={`form-control ${
                                                                            errors.profile_image ? "is-invalid" : ""
                                                                        }`}
                                                                        id="profile_image"
                                                                    />
                                                                    {errors.profile_image && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.profile_image}
                                                                        </div>
                                                                    )}
                                                                    {/* test input cropped photo */}
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="cover_image">
                                                                        Background Image{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        name="cover_image"
                                                                        onChange={(e) => handleFileChange(e, "pane5")}
                                                                        validate={{
                                                                            required: true,
                                                                        }}
                                                                        type="file"
                                                                        className={`form-control ${
                                                                            errors.cover_image ? "is-invalid" : ""
                                                                        }`}
                                                                        id="cover_image"
                                                                    />

                                                                    {errors.cover_image && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.cover_image}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                }}
                                                                disabled={activeTab === 1}
                                                                onClick={() => {
                                                                    toggleTab(activeTab - 1);
                                                                    toggleTabProgress(activeTab - 1);
                                                                    setIsNext(false);
                                                                }}
                                                            >
                                                                Previous
                                                            </Button>

                                                            <Button
                                                                type="submit"
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                }}
                                                                //  onClick={handleValidSubmit}
                                                                disabled={isSubmittingRegistration}
                                                            >
                                                                {isSubmittingRegistration ? (
                                                                    <>
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                        <span className="ms-2">Creating User..</span>
                                                                    </>
                                                                ) : (
                                                                    "Register"
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">
                                    By registering you agree to the Route Money{" "}
                                    <Link to="#" className="text-primary">
                                        {" "}
                                        Terms of Use
                                    </Link>
                                </p>
                                <p>
                                    Already have an account ?{" "}
                                    <Link to="/route-money/merchant/login" className="fw-medium text-primary">
                                        {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* image crop modal */}

                <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                    <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                        Crop {selectedImageType === "profile_image" ? "Profile" : "Cover"} Image
                    </ModalHeader>
                    <ModalBody>
                        {imgSrc && (
                            <Row>
                                <Col md="6">
                                    <ReactCrop
                                        crop={crop}
                                        onChange={(newCrop) => setCrop(newCrop)}
                                        onComplete={(newCrop) => setCompletedCrop(newCrop)}
                                        aspect={aspect}
                                    >
                                        <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            src={imgSrc}
                                            onLoad={() => {
                                                setCrop({ unit: "%", width: 50, aspect });
                                            }}
                                            style={{ maxWidth: "100%", maxHeight: "400px" }}
                                        />
                                    </ReactCrop>
                                </Col>
                                <Col md="6">
                                    {completedCrop && (
                                        <canvas
                                            ref={previewCanvasRef}
                                            style={{
                                                border: "1px solid black",
                                                width: "100%",
                                                maxHeight: "400px",
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        )}
                    </ModalBody>
                    <div className="modal-footer">
                        <Button color="secondary" onClick={() => setModalOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            color={isLoading ? "secondary" : "primary"}
                            onClick={() => handleSaveCrop("pane5")} // Pass "pane4" or any pane value as needed
                            disabled={isLoading}
                        >
                            {isLoading ? "Saving ..." : "Save"}
                        </Button>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};

export default connect(mapStateToProps)(Register);
