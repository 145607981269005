export default function validateRequiredFields(allFormData, otpVerificationOutcome) {
    const requiredFields = [
        "pane1.name",
        "pane1.contact_person",
        "pane1.phone_number",
        "pane1.alt_phone_number",
        "pane1.location",
        "pane1.email",
        "pane3.sharing_type",
        "pane3.sharing_value",
        "pane3.bank",
        "pane4.password",
        "pane4.confirmPassword",
        "pane5.profile_image",
        "pane5.cover_image",
        "pane5.specific_type",
    ];

    for (const field of requiredFields) {
        const value = nestedGet(allFormData, field); // Ensure accurate nested value retrieval

        if (value === null || value === undefined || value === "") {
            // Informative user message based on field context
            if (field.includes("password")) {
                return "Please enter your password.";
            } else if (field.includes("confirm")) {
                return "Please confirm your password.";
            } else {
                const fieldName = field.split(".")[1].replace(/_/g, " "); // Human-readable field name
                return `Please provide your ${fieldName}.`;
            }
        }
    }

    if (!otpVerificationOutcome) {
        return "Please verify your OTP";
    }

    return null; // No unfilled required fields found
}

function nestedGet(obj, path) {
    return path.split(".").reduce((acc, key) => (acc && acc[key] ? acc[key] : null), obj);
}
